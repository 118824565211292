(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/video-player/views/video-player.js') >= 0) return;  svs.modules.push('/components/components/video-player/views/video-player.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.video_player=_cmps.video_player||{};
_cmps.video_player.templates=_cmps.video_player.templates||{};
svs.components.video_player.templates.video_player = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"padding-bottom:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"heightPercent") || (depth0 != null ? lookupProperty(depth0,"heightPercent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heightPercent","hash":{},"data":data,"loc":{"start":{"line":1,"column":119},"end":{"line":1,"column":136}}}) : helper)))
    + "%\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"video-container js-video-container\" data-videoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":73}}}) : helper)))
    + "\"  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"heightPercent") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":76},"end":{"line":1,"column":145}}})) != null ? stack1 : "")
    + " itemprop=\"video\" itemscope itemtype=\"http://schema.org/VideoObject\" data-json-ld='"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"jsonLDTags") || (depth0 != null ? lookupProperty(depth0,"jsonLDTags") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"jsonLDTags","hash":{},"data":data,"loc":{"start":{"line":1,"column":228},"end":{"line":1,"column":244}}}) : helper))) != null ? stack1 : "")
    + "'>\n  <iframe  src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"src") || (depth0 != null ? lookupProperty(depth0,"src") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"src","hash":{},"data":data,"loc":{"start":{"line":2,"column":16},"end":{"line":2,"column":23}}}) : helper)))
    + "\" class=\"js-video-"
    + alias4(((helper = (helper = lookupProperty(helpers,"videoId") || (depth0 != null ? lookupProperty(depth0,"videoId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":52}}}) : helper)))
    + " solid-stat js-solid-iframe\" height=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":2,"column":89},"end":{"line":2,"column":99}}}) : helper)))
    + "\" width=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":2,"column":108},"end":{"line":2,"column":117}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":126},"end":{"line":2,"column":135}}}) : helper)))
    + "\" allowFullScreen ></iframe>\n</div>\n";
},"useData":true});
Handlebars.partials['components-components-video_player-video_player'] = svs.components.video_player.templates.video_player;
})(svs, Handlebars);


 })(window);