(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/video-player/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/components/video-player/assets/javascripts/init.js');

'use strict';

const logger = svs.core.log.getLogger('component:videoPlayer');
const eventNotifier = new svs.core.Events(() => {});

function stateCheck() {
  $('.js-video-container').each((index, vid) => {
    const video = $(vid);
    let videoTitle;
    if (!video.attr('data-duration')) {
      svs.components.video_player.getDuration(video.attr('data-videoId'), data => {
        if (Boolean(data.value) && data.value > 0) {
          video.attr('data-duration', data.value);
          svs.components.video_player.getMetadata(video.attr('data-videoId'), metadata => {
            videoTitle = "".concat(metadata.id, "-").concat(metadata.value.title);
            video.attr('data-metadata-title', videoTitle);
            svs.components.analytics.trackEvent({
              action: 'Start',
              category: 'Video',
              opt_label: videoTitle,
              opt_value: 1
            });
          });
        }
      });
    }
  });
}

function receiveMessage(event) {
  if (event.origin !== "https://".concat(svs.core.config.data.svsconfig.solidTango)) {
    return;
  }
  if (event && event.data) {
    try {
      const data = JSON.parse(event.data);
      switch (data.func) {
        case 'ready':
          eventNotifier.trigger("".concat(data.id, "_ready"), {
            id: data.id,
            value: data.args
          });
          break;
        case 'position':
          eventNotifier.trigger("".concat(data.id, "_position"), {
            id: data.id,
            value: data.args
          });
          break;
        case 'duration':
          eventNotifier.trigger("".concat(data.id, "_duration"), {
            id: data.id,
            value: data.args
          });
          break;
        case 'playing':
          eventNotifier.trigger("".concat(data.id, "_playing"), {
            id: data.id,
            value: data.args
          });
          break;
        case 'metadata':
          eventNotifier.trigger("".concat(data.id, "_metadata"), {
            id: data.id,
            value: data.args
          });
          break;
        default:
          logger.debug('Unknown event received. Ignoring.');
          break;
      }
    } catch (error) {
    }
  }
}

function sendMessage(videoId, command, optValue) {
  if (videoId) {
    var _$$;
    const message = {
      id: videoId,
      event: 'message',
      func: command
    };
    if (optValue) {
      message.args = optValue;
    }
    (_$$ = $(".js-video-".concat(videoId))[0]) === null || _$$ === void 0 || (_$$ = _$$.contentWindow) === null || _$$ === void 0 || _$$.postMessage(JSON.stringify(message), "https://".concat(svs.core.config.data.svsconfig.solidTango));
  }
}

function getPlayerValue(videoId, command, callback) {
  if (jQuery.isFunction(callback)) {
    const listener = function (data) {
      eventNotifier.off("".concat(videoId, "_").concat(command), listener);
      try {
        callback(data);
      } catch (err) {
      }
    };
    eventNotifier.on("".concat(videoId, "_").concat(command), listener);
    sendMessage(videoId, command);
  }
}

window.addEventListener('message', receiveMessage, false);

svs.components.video_player.init = function (options) {
  if (!options.videoId) {
    logger.error('Missing videoId');
    return;
  }

  if (options.height && options.width) {
    options.heightPercent = options.height / options.width * 100;
  }
  if (options.api) {
    options.src += options.src.indexOf('?') ? '&api=true' : '?api=true';
  }

  if (Boolean(options) && Boolean(options.selector) && Boolean(options.src)) {
    $(options.selector).append(svs.components.video_player.templates.video_player(options));
  } else {
    logger.error('Error in options object', options);
  }
};

svs.components.video_player.play = function (videoId) {
  if (!videoId) {
    logger.warn('No videoId specified to play.');
    return;
  }
  sendMessage(videoId, 'play');
};

svs.components.video_player.stop = function (videoId) {
  if (!videoId) {
    logger.warn('No videoId specified to stop.');
    return;
  }
  sendMessage(videoId, 'stop');
};

svs.components.video_player.pause = function (videoId) {
  if (!videoId) {
    logger.warn('No videoId specified to pause.');
    return;
  }
  sendMessage(videoId, 'pause');
};

svs.components.video_player.togglePlay = function (videoId) {
  if (!videoId) {
    logger.warn('No videoId specified to togglePlay.');
    return;
  }
  sendMessage(videoId, 'togglePlay');
};

svs.components.video_player.seek = function (videoId, position) {
  if (!videoId) {
    logger.warn('No videoId specified to seek.');
    return;
  }
  if (!/^\d+$/.test(position)) {
    logger.warn('No valid position specified to seek.');
    return;
  }
  sendMessage(videoId, 'seek', position);
};

svs.components.video_player.volume = function (videoId, volume) {
  if (!videoId) {
    logger.warn('No videoId specified to volume.');
    return;
  }
  if (!jQuery.isNumeric(volume)) {
    logger.warn('No valid value specified to volume.');
    return;
  }
  sendMessage(videoId, 'volume', volume);
};

svs.components.video_player.isReady = function (videoId, callback) {
  getPlayerValue(videoId, 'ready', callback);
};

svs.components.video_player.getPosition = function (videoId, callback) {
  getPlayerValue(videoId, 'position', callback);
};

svs.components.video_player.getDuration = function (videoId, callback) {
  getPlayerValue(videoId, 'duration', callback);
};

svs.components.video_player.getMetadata = function (videoId, callback) {
  getPlayerValue(videoId, 'metadata', callback);
};

svs.components.video_player.isPlaying = function (videoId, callback) {
  getPlayerValue(videoId, 'playing', callback);
};
function setFocusOnTab() {
  $(document).on('keydown', '*', e => {
    if (e.key === 'Tab') {
      $('.iframe-focus-outline').removeClass('iframe-focus-outline');
      setTimeout(() => {
        if (document.activeElement && $(document.activeElement).hasClass('js-solid-iframe')) {
          $(document.activeElement).parent('.js-video-container').parent().addClass('iframe-focus-outline');
        }
      }, 50);
    }
  }).on('keyup', '*', () => {
    $('.iframe-focus-outline').removeClass('iframe-focus-outline');
  }).on('mousedown', '*', () => {
    $('.iframe-focus-outline').removeClass('iframe-focus-outline');
  }).on('blur', '*', () => {
    $('.iframe-focus-outline').removeClass('iframe-focus-outline');
  });
}
if (svs.core.detect.formfactor.desktop()) {
  setFocusOnTab();
}
setInterval(stateCheck, 2000);

 })(window);